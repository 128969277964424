.main-header {
	overflow: hidden;
	width: 100%;
	.header {
		display: flex;
		justify-content: center;
		align-items: center;
		border-top: 1px solid black;
		border-bottom: 2px solid black;
		margin-top: 3.5em;
		
		.title {
			display: inline-flex;
			margin: 0.3em;
			a {
				color: #212529;
			}
		}
		.subtitle {
			display: none;
		}
	}
	
	.slogan {
		border-bottom: 1px solid black;
		letter-spacing: 1px;
		p {
			text-align: center;
			text-transform: uppercase;
			font-size: 0.7rem;
			margin: 0.5em 1em;
		}
	}
}

@media (min-width: 48em) and (orientation: portrait) {
	.main-header {
		.header {
			margin-top: 0;
			.title {
				font-size: 2em;
			}
			.subtitle {
				display: flex;
				flex-direction: column;
				text-align: right;
				padding: 0 2em;
				text-transform: uppercase;
				font-size: 0.8rem;
				.roles {
					display: flex;
					flex-direction: column;
					text-align: center;
					width: 80px;

					span {
						text-align: center;
					}
				}
			}
		}
	}
}

@media (min-width: 64em) and (orientation: landscape) {
	.main-header {
		.header {
			margin-top: 0;
			.title {
				font-size: calc(1rem + ((1vw - 0.48em) * 5.7619));
			}
			.subtitle {
				display: flex;
				flex-direction: column;
				text-align: right;
				padding: 0 2em;
				text-transform: uppercase;
				font-size: 0.8rem;
				.roles {
					display: flex;
					flex-direction: column;
					text-align: center;
					width: 80px;

					span {
						text-align: center;
					}
				}
				
			}
		}
	}
}


@media (min-width: 64em) and (orientation: portrait) {
	.main-header {
		.header {
			margin-top: 0;
			.title {
				font-size: calc(1rem + ((1vw - 0.48em) * 5.7619));
			}
			.subtitle {
				font-size: 1rem;
				padding: 0 3em;
			}
		}
	}
}

@media (min-width: 83em) and (orientation: landscape) {
	.main-header {
		.header {
			margin-top: 0;
			.title {
				font-size: calc(1rem + ((1vw - 0.48em) * 3.7619));
				margin: 0.1em;
			}
			.subtitle {
				font-size: 1.2rem;
				padding: 0 3em;
				display: flex;
				text-transform: uppercase;
				.roles {
					display: flex;
					flex-direction: column;
					text-align: center;
					width: 80px;
					font-size: .7em;

					span {
						text-align: center;
					}
				}
			}
		}
	}
}
