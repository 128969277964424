.blur {
  filter: blur(5px) grayscale(10%);
}

.contrast {
  filter: brightness(90%);
  filter: contrast(400%);
}

.saturate {
  filter: saturate(500%);
}

.hue {
  filter: hue-rotate(270deg);
}

.invert {
  filter: invert(100%);
}

@mixin info($direction, $align) {
  flex-direction: $direction;
  justify-content: $align;
}

.home-article {
  &:nth-child(2) {
    border-top: none;
  }
  &:nth-child(3) {
    border-top: none;
  }

  .article-title {
    height: auto;
    margin: .2em 0 .2em;
  }

  .article-info {
    margin-bottom: .5em;
  }

  .article-content {
    .image-container {
      .article-image {
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 0.2em;
        padding-top: 75%;
      }
    }
    .article-excerpt {
      margin: 1em 0;
      .date {
        font-family: 'New York Extra Large Regular Italic';
        font-weight: 100;
        font-size: 0.8rem;
        text-align: left;
        border-right: 1px solid black;
        padding-right: .5em;
        font-weight: bold;
      }
      .excerpt {
        padding-left: .5em;
      }
    }
  } 
}

@media only screen and (min-width: 48em) and (orientation: portrait) {
  .home-article.interviews {
    &:nth-child(2) {
      .article-image {
        padding-top: 135%;
      }
    }
  }
}

@media only screen and (min-width: 64em) and (orientation: landscape) {
  .home-article.pixels {
    &:first-child {
      grid-column: 1 / 3;
      .article-content {
        .image-container {
          .article-image {
           padding-top: 120%;
          }
        }
      }
    }

    &:nth-child(2) {
      grid-column: 3 / 7;
      .article-content {
        display: flex;
        flex-direction: row-reverse;
        .image-container {
          width: 100%;
          .article-image {
            background-size: contain;
            padding-top: 120%;
           }
        }
        .article-excerpt {
          width: 70%;
          padding-right: 1em;
          margin-top: 0;
        }
      }
    }
  }

  .home-article.culture {
    &:first-child {
      grid-column: 1 / 3;
    }

    &:nth-child(2) {
      grid-column: 3 / 5;
    }

    &:nth-child(3) {
      grid-column: 5 / 7;
    }
  }

  .home-article.interviews {
    &:first-child {
      grid-column: 1 / 4;
      .article-title {
        margin: 0.5em 0 0.2em 0;
      }
 
      .article-content {
        display: flex;
        flex-direction: column-reverse;
        .image-container {
          width: 100%;
        }
        .article-excerpt {
          width: 100%;
          margin-top: 0;
        }
      } 
    }

    &:nth-child(2) {
      grid-column: 4 / 7;
      .article-content {
        display: flex;
        flex-direction: row-reverse;
        .image-container {
          width: 100%;
          .article-image {
            padding-top: 176%;
          }
        }
        .article-excerpt {
          width: 100%;
          padding-right: 1em;
          margin-top: 0;
        }
      }
      .article-title {
        margin: 0.5em 2em 0.2em 0;
      }
    }
  }
}

@media only screen and (min-width: 83em) {
  .home-article.pixels {
    &:first-child {
      grid-column: 1 / 5;
    }

    &:nth-child(2) {
      grid-column: 5 / 13;
      .article-title {
        margin-bottom: 0;
      }
      .article-info {
        width: 38%;
      }
    }
  }

  .home-article.culture {
    &:first-child {
      grid-column: 1 / 5;
      .article-image {
        padding-top: 160%
      }
    }

    &:nth-child(2) {
      grid-column: 5 / 11;
      .article-content {
        display: flex;
        flex-flow: column-reverse;
      }
      .article-excerpt {
        margin-top: 0;
      }
    }

    &:nth-child(3) {
      grid-column: 11 / 15;
      .article-image {
        padding-top: 150%;
      }
    }
  }

  .home-article.interviews {
    &:first-child {
      grid-column: 1 / 7;
    }

    &:nth-child(2) {
      grid-column: 7 / 15;
      .article-title {
        margin: .5em 0 .2em;
      }
      .article-info {
        width: 44%;
      }
    }
  }
}
