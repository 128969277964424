.home-container {
  margin: 0 2em;
}

@media only screen and (min-width: 25em) and (orientation: landscape) {
  .home-container {
    margin: 1em 4em;
  }
}

@media only screen and (min-width: 48em) and (orientation: portrait) {
  .category-container {
    margin: 1em 4em;
  }
}

@media (min-width: 90em) {
 .home-container {
   margin: 0 16em;
 }
}
