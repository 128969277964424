
.container-titles {
  .category-titles {
    text-transform: uppercase;
    margin-bottom: 0.3em;
    border-bottom: 1px solid black;
    padding-bottom: .3em;
  }
}


@media only screen and (min-width: 25em) and (orientation: landscape) {
  .container {
    display: grid;
    grid-gap: 4em;

    &.pixels {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (min-width: 48em) and (orientation: portrait) {
  .container {
    display: grid;
    grid-gap: 4em;
    margin-bottom: 1em;
    &.pixels, &.culture, &.interviews {
      grid-template-columns: repeat(auto-fill, minmax(250px,1fr));
    }
  }
}

@media only screen and (min-width: 64em) and (orientation: landscape) {
  .container {
    display: grid;
    grid-gap: 2em;
    margin-bottom: 2em;
    &.pixels, &.culture, &.interviews {
      grid-template-columns: repeat(3, minmax(auto, 1fr));
      grid-auto-columns: minmax(auto-fill, auto);
      grid-auto-flow: row dense;
    }
  }
}

@media (min-width: 85em) {
  .container {
    grid-gap: 4em;
    &.pixels, &.culture, &.interviews {
      grid-template-columns: repeat(2, minmax(auto, 1fr));
      grid-template-rows: minmax(3, minmax(auto, 1fr));
      grid-auto-columns: minmax(auto-fill, auto);
      grid-auto-rows: max-content;
      grid-auto-flow: row dense;
    }
  }
}
