
.projects-container {
  margin: 2em;
  .espanlol {
    .header {
      display: grid;
      grid-template-columns: 1fr;
      .project-header {
        width: 100%;
        height: auto;
        margin-bottom: 2em;

        .project-image {
          width: 100%;
          padding-right: 1em;
        }
      }
      .description {
        .explanation {
          display: flex;
          flex-direction: column;
          h3 {
            margin-top: 0;
            margin-bottom: 0;
          }
          p {
            margin-top: 0.2em;
          }
        }
        .tags {
          display: flex;
          flex: 2;
          flex-direction: column;
          h3 {
            margin-bottom: 0;
          }
          p {
            margin-top: 0.2em;
          }
        }
      }
    }
  }
  .chapters-container {
    .chapters-header {
      display: flex;
      align-content: flex-start;
      margin-top: 2em;
      border-bottom: 1px solid black;
      text-transform: uppercase;
    }
    .chapters {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      place-items: center;
      grid-gap: 1em;
      .chapters-image {
        background-repeat: no-repeat;
        background-size: contain;
        height: 220px;
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 25em) and (orientation: landscape) {
  .projects-container {
    margin: 1em 4em;
    .espanlol {
      .header {
        display: block;
        .description {
          .explanation {
            display: flex;
            flex-direction: column;
            h3 {
              margin-top: 0;
              margin-bottom: 0;
            }
            p {
              margin-top: 0.2em;
            }
          }
          .tags {
            display: flex;
            flex: 2;
            flex-direction: column;
            h3 {
              margin-bottom: 0;
            }
            p {
              margin-top: 0.2em;
            }
          }
        }
      }

      .chapters-container {
        .chapters {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          place-items: center;
          grid-gap: 1em;
          .chapters-image {
            background-repeat: no-repeat;
            background-size: contain;
            height: 500px;
            width: 100%;
          }
        }
      } 
    }
  }
}

@media only screen and (min-width: 48em) and (orientation: portrait) {
  .projects-container {
    margin: 1em 4em;
    .espanlol {
      .header {
        .description {
          .explanation {
            display: flex;
            flex-direction: column;
            h3 {
              margin-top: 0;
              margin-bottom: 0;
            }
            p {
              margin-top: 0.2em;
            }
          }
          .tags {
            display: flex;
            flex: 2;
            flex-direction: column;
            h3 {
              margin-bottom: 0;
            }
            p {
              margin-top: 0.2em;
            }
          }
        }
      }

      .chapters-container {
        .chapters {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          place-items: center;
          grid-gap: 1em;
          .chapters-image {
            height: 300px;
            width: 100%;
          }
        }
      } 
    }
  }
}

@media only screen and (min-width: 64em) and (orientation: landscape) {
  .projects-container {
    .espanlol {
      .header {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .project-header {
          height: auto;
          margin-right: 2em;
        }
      }
      .chapters-container {
        .chapters {
          grid-template-columns: repeat(3, 1fr);
          .chapters-image {
            height: 430px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 64em) and (orientation: portrait) {
  .projects-container {
    .espanlol {
      .header {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .project-header {
          height: auto;
          margin-right: 2em;
        }
      }
      .chapters-container {
        .chapters {
          grid-template-columns: repeat(3, 1fr);
          .chapters-image {
            height: 430px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 83em) and (orientation: landscape) {
  .projects-container {
    .espanlol {
      .chapters-container {
        .chapters {
          grid-gap: 2em;
          .chapters-image {
            height: 575px;
          }
        }
      }
    }
  }
}

@media (min-width: 90em) {
 .projects-container {
    margin: 3em 16em;
   .espanlol {
    .chapters-container {
      .chapters {
        grid-gap: 2em;
        .chapters-image {
          height: 606px;
        }
      }
    }
   }
 }
}
