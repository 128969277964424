.blur {
    filter: blur(5px) grayscale(10%);
  }

.contrast {
  filter: brightness(90%);
  filter: contrast(400%);
}

.saturate {
  filter: saturate(500%);
}

.hue {
  filter: hue-rotate(270deg);
}

.invert {
  filter: invert(100%);
}

@mixin info($direction, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $align;
}

@mixin each($num) {
  &:nth-child(#{$num}n) {
    @content
  }
}

.category-article {
  .article-title {
    height: auto;
    margin: .2em 0 .2em;
  }
  .article-info {
    margin-bottom: .4em;
  }
  .article-content {
    @include info(column, space-between);
    .image-container {
      width: 100%;
      height: 100%;
      .article-image {
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 0.2em;
        padding-top: 75%;
      }
    }
    .article-excerpt {
      margin-top: 1em;
      .date {
        font-family: 'New York Extra Large Regular';
        font-size: .9em;
        font-weight: bold;
        border-right: 1px solid black;
        padding-right: .5em;
      }
      .excerpt {
        padding-left: .5em;
       
      }
    }
  }
}

@media only screen and (min-width: 48em) and (orientation: portrait) {
  .category-article {
    &:nth-child(2) {
      border-top: 1px solid black;
    }
  }
}


@media only screen and (min-width: 85em) {
  .category-article {
    &:nth-child(3) {
      border-top: none;
    }
    $width: 120%, 70%, 70%, 120%, 120%, 70%, 70%, 120%, 80%, 110%, 80%, 110%;
    $justify: start, end, start, end, start, end, start, end, start, end, start, end;

    @for $i from 1 through length($width) {
      &:nth-child(#{length($width)}n+#{$i}) {
          width: nth($width, $i)
      }
    }

    @for $i from 1 through length($justify) {
      &:nth-child(#{length($justify)}n+#{$i}) {
        justify-self: nth($justify, $i)
      }
    }
  }

  .category-article:first-child {
    .article-title {
      margin-bottom: 0;
    }
    .article-info {
      margin: .2em 0 .5em;
    }
    .article-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      .image-container {
        .article-image {
         padding-top: 120%;
        }
      }
      .article-excerpt {
        padding-left: 1em;
        margin-top: 0;
        width: 80%;
      }
    }
  }
  .category-article:nth-child(2) {
    .article-content {
      display: flex;
      flex-direction: column;
      .article-excerpt {
        margin-top: 1em;
      }
    }
  }

  .category-article {
    @include each(3) {
      .article-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .article-excerpt {
          margin-top: 1em;
        }
      }
    }
  }

  .category-article {
    @include each(4) {
      .article-title {
        margin-bottom: 0;
        width: 70%
      }
      .article-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .article-excerpt {
          margin-top: 0;
          padding-left: 1em;
          width: 80%;
        }
      }
      
    }
  }

  .category-article {
    @include each(5) {
      .article-content {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        .article-excerpt {
          margin-top: 0;
          padding-right: 1em;
          width: 80%;
        }
      }
    }
  }
}

//https://medium.com/@andybarefoot/a-masonry-style-layout-using-css-grid-8c663d355ebb
