.container-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid black;
  margin-bottom: .5em;

  &.pixels {
    flex-direction: row;
  }

  &.culture, &.interviews {
    flex-direction: row;
  }

  .category-title {
    text-transform: uppercase;
    margin-bottom: 0.3em;
  }

  .category-more {
    font-family: 'New York Extra Large Regular Italic';
    font-size: 1rem;
    margin-right: 1em;
    cursor: pointer;
  }
}

@media only screen and (min-width: 25em) and (orientation: landscape) {
  .row {
    display: grid;
    grid-gap: 4em;
    &.pixels, &.interviews {
      grid-template-columns: repeat(2, 1fr);
    }
    &.culture {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (min-width: 48em) and (orientation: portrait) {
  .row {
    display: grid;
    grid-gap: 4em;
    margin-bottom: 1em;
    &.pixels, &.interviews {
      grid-template-columns: repeat(2, 1fr);
    }

    &.culture {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media only screen and (min-width: 64em) and (orientation: landscape) {
  .row {
    display: grid;
    grid-gap: 4em;
    margin-bottom: 2em;
    &.pixels, &.culture, &.interviews {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}

@media (min-width: 85em) {
  .row {
    grid-gap: 6em;
    margin-bottom: 3em;
    &.pixels {
      grid-template-columns: repeat(12, 1fr);
    }

    &.culture, &.interviews  {
      grid-template-columns: repeat(14, 1fr);
    }
  }
}
